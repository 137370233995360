<template>
  <ClientOnly>
    <AtroPopover
      theme="onboarding"
      placement="auto"
      popper-class="!z-2"
      ref="popperRef"
      :arrow-padding="32"
      :auto-hide="false"
      :delay="{ show: 500, hide: 0 }"
      :popper-triggers="[]"
      :shown="isShown"
      :triggers="[]"
      v-bind="$attrs"
    >
      <template #trigger><slot /></template>
      <template #content>
        <AtroContent
          class="max-w-xs space-y-6 text-white sm:max-w-[409px]"
          direction="col"
          items="center"
        >
          <AtroCloseButton
            class="!absolute -right-10 -top-10 mr-1 mt-1"
            type="modal"
            color="light"
            @click="skipWalkthrough"
          />
          <AtroContent class="w-full">
            <AtroParagraph semibold size="lg" :text="walkthroughStep?.text" />
          </AtroContent>
          <AtroContent class="w-full" justify="between" wrap="nowrap">
            <AtroButton
              type="dark"
              :icon-right="buttonIcon"
              :text="buttonText"
              @click="nextWalkthroughStep"
            />
            <AtroStepsBar
              shrink
              class="!w-auto place-self-end"
              size="sm"
              type="light"
              :bulge-on-active="false"
              :current-step-index="currentWalkthroughStepIndex"
              :divided="false"
              :steps-count="walkthroughSteps.length"
            />
          </AtroContent>
        </AtroContent>
      </template>
    </AtroPopover>
    <template #fallback>
      <slot />
    </template>
  </ClientOnly>
</template>

<script setup lang="ts">
export interface Props {
  stepKey: string
}

withDefaults(defineProps<Props>(), {  })

const {
  currentWalkthroughStep,
  currentWalkthroughStepIndex,
  isWalkthroughActive,
  nextWalkthroughStep,
  skipWalkthrough,
  walkthroughSteps,
} = useOnboarding()

const popperRef = ref()
const resizeInterval = ref()
const walkthroughStep = ref(
  walkthroughSteps.value.find((step) => step.key === __props.stepKey),
)

const isLastWalkthroughStep = computed(
  () =>
    walkthroughSteps.value.findIndex((step) => step.key === __props.stepKey) ===
    walkthroughSteps.value.length - 1,
)
const isShown = computed(
  () =>
    import.meta.client &&
    currentWalkthroughStep.value?.key === __props.stepKey &&
    isWalkthroughActive.value,
)

const buttonIcon = computed<IconName>(() =>
  isLastWalkthroughStep.value ? 'check' : 'chevron-right',
)

const buttonText = computed(() =>
  isLastWalkthroughStep.value ? 'Done' : 'Next',
)

onMounted(() => {
  if (popperRef.value?.popper?.onResize) {
    resizeInterval.value = setInterval(popperRef.value.popper.onResize, 100)
  }
})

onBeforeUnmount(() => {
  clearInterval(resizeInterval.value)
})
</script>
